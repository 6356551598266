
import Login from '~/components/Auth/Login/Login'
import ResetEmailPassword from '~/components/Auth/ResetPassword/ResetEmailPassword'

export default {
  name: 'IndexPage',

  components: {
    Login,
    ResetEmailPassword
  },

  layout: 'auth',

  middleware: 'loggedIn',

  data: () => {
    return {
      login: true,
      resetPassword: false
    }
  },

  created () {
    this.$nuxt.$on('viewResetPassword', () => {
      this.resetPassword = true
    })
  },

  methods: {
    changeView (value) {
      this.login = value
      this.resetPassword = false
    }

  }
}
