
import MessageAlert from '~/mixins/MessageAlert'
import FormRules from '~/mixins/Forms/FormRules'
import Loading from '~/mixins/Loading'

export default {
  name: 'ResetEmailPassword',

  mixins: [
    MessageAlert,
    FormRules,
    Loading
  ],

  data: () => {
    return {
      resetEmailForm: {
        email: ''
      },
      thankYou: false,
      message: ''
    }
  },

  methods: {
    submitForm () {
      this.$refs.resetEmailForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$axios.post('/api/reset_password', {
            email: this.resetEmailForm.email
          })
            .then((response) => {
              this.message = response.data.result
            })
            .catch((e) => {
              this.MessageAlert({ type: 'error', message: e.response.data.error.email, duration: 10000, showClose: true })
            })

          this.Loading()
        } else {
          return false
        }
      })
    }
  }
}
