
import MessageAlert from '~/mixins/MessageAlert'
import FormRules from '~/mixins/Forms/FormRules'
import Loading from '~/mixins/Loading'
import MessageError from '~/mixins/Forms/MessageError'

export default {
  name: 'Login',

  mixins: [
    MessageAlert,
    MessageError,
    FormRules,
    Loading
  ],

  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    submitForm () {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$auth.loginWith('local', {
            data: {
              email: this.loginForm.email,
              password: this.loginForm.password
            }
          })
            .catch((error) => {
              const data = error.response.data

              this.MessageError(data.error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    },

    async googleLogin () {
      this.Loading()

      await this.$axios.get('/api/oauth2', {
        params: {
          redirect_uri: process.env.APP_URL + '/auth/google'
        }
      })
        .then((response) => {
          window.location.href = response.data.result.url
        })
        .catch((error) => {
          const data = error.response.data

          this.MessageError(data.error)
        })

      this.Loading()
    }
  }
}
